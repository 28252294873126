/* External dependencies */
import { debounceTime, filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/averspay';
import { cleanPayload } from '../../common/helpers';
import {
  ListServices,
  ListServicesAction,
  ListServicesActionTypes,
  listServicesFailed,
  listServicesSucceeded,
  ListServicesSuccess,
} from './actions';
import { listServicesQuery } from './queries';

export default function listServicesEpic(action$, state$) {
  return action$.pipe(
    filter((action: ListServicesAction) => action.type === ListServicesActionTypes.LIST_SERVICES_REQUEST),
    debounceTime(300),
    switchMap((action: ListServices) =>
      listServices(action, state$.value.services.size).catch((error) => listServicesFailed(error)),
    ),
  );
}

export async function listServices(
  { filter = {}, currentPage, locale, searchString }: ListServices,
  size: number,
): Promise<ListServicesSuccess> {
  const graphQLClient = await getClient();

  const {
    data: {
      listServices: { services, total },
    },
  } = await graphQLClient.query({
    query: listServicesQuery,
    variables: {
      input: {
        filter: cleanPayload(filter),
        locale,
        from: (currentPage - 1) * size,
        query: searchString,
        size,
      },
    },
  });

  const servicesWithoutItems = services?.filter((service) => service?.typename !== 'Item');

  return listServicesSucceeded(total, servicesWithoutItems);
}
