/* External dependencies */
import gql from 'graphql-tag';

export const listAccountsQuery = gql`
  query listAccounts($input: ListAccountsInput!) {
    listAccounts(input: $input) {
      total
      totalAmount
      accounts {
        id
        name
        type
        user {
          id
        }
        balance {
          amount
          currency
        }
        status {
          statusType
        }
      }
    }
  }
`;
