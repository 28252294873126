/*Local dependencies */
import { DeviceChange } from '../../../../components/cashCollections/types';

export enum ConfirmCashCollectionDepositTypes {
  CONFIRM_CASH_COLLECTION_DEPOSIT_REQUEST = 'CONFIRM_CASH_COLLECTION_DEPOSIT_REQUEST',
  CONFIRM_CASH_COLLECTION_DEPOSIT_SUCCESS = 'CONFIRM_CASH_COLLECTION_DEPOSIT_SUCCESS',
  CONFIRM_CASH_COLLECTION_DEPOSIT_ERROR = 'CONFIRM_CASH_COLLECTION_DEPOSIT_ERROR',
  CONFIRM_CASH_COLLECTION_DEPOSIT_RESET = 'CONFIRM_CASH_COLLECTION_DEPOSIT_RESET',
}

export type ConfirmCashCollectionDepositActions =
  | ConfirmCashCollectionDeposit
  | ConfirmCashCollectionDepositSuccess
  | ConfirmCashCollectionDepositError
  | ConfirmCashCollectionDepositReset;

export interface ConfirmCashCollectionDeposit {
  type: ConfirmCashCollectionDepositTypes.CONFIRM_CASH_COLLECTION_DEPOSIT_REQUEST;
  id: string;
  note: string;
}

export interface ConfirmCashCollectionDepositSuccess {
  type: ConfirmCashCollectionDepositTypes.CONFIRM_CASH_COLLECTION_DEPOSIT_SUCCESS;
  cashCollection: DeviceChange;
}

export interface ConfirmCashCollectionDepositError {
  type: ConfirmCashCollectionDepositTypes.CONFIRM_CASH_COLLECTION_DEPOSIT_ERROR;
  error: Error;
}

export interface ConfirmCashCollectionDepositReset {
  type: ConfirmCashCollectionDepositTypes.CONFIRM_CASH_COLLECTION_DEPOSIT_RESET;
}

export function confirmCashCollectionDeposit(id: string, note: string): ConfirmCashCollectionDeposit {
  return {
    type: ConfirmCashCollectionDepositTypes.CONFIRM_CASH_COLLECTION_DEPOSIT_REQUEST,
    id,
    note,
  };
}

export function confirmCashCollectionDepositSuccess(cashCollection: DeviceChange): ConfirmCashCollectionDepositSuccess {
  return {
    type: ConfirmCashCollectionDepositTypes.CONFIRM_CASH_COLLECTION_DEPOSIT_SUCCESS,
    cashCollection,
  };
}

export function confirmCashCollectionDepositFailed(error: Error): ConfirmCashCollectionDepositError {
  return {
    type: ConfirmCashCollectionDepositTypes.CONFIRM_CASH_COLLECTION_DEPOSIT_ERROR,
    error,
  };
}

export function confirmCashCollectionDepositReset(): ConfirmCashCollectionDepositReset {
  return {
    type: ConfirmCashCollectionDepositTypes.CONFIRM_CASH_COLLECTION_DEPOSIT_RESET,
  };
}
