/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

/* Local dependencies */
import { getClient } from '../../../clients/averspay';
import { TopUpBalanceAction, TopUpBalanceActionTypes, topUpBalanceError, topUpBalanceSucceeded } from './actions';
import { topUpBalanceMutation } from './mutations';

const ONE_PAYMENT_LIMIT = 200000;

export interface TopUpBalanceResponse {
  amount: number;
}

export default function rewirePaymentEpic(action$) {
  return action$.pipe(
    filter((action: TopUpBalanceAction) => action.type === TopUpBalanceActionTypes.TOP_UP_BALANCE_REQUEST),
    switchMap((action: TopUpBalanceAction) =>
      topUpBalance(action)
        .then(topUpBalanceSucceeded)
        .catch((error) => topUpBalanceError(error)),
    ),
  );
}

export async function topUpBalance(action): Promise<TopUpBalanceResponse> {
  const graphQLClient = await getClient();
  const { topUpBalance } = action;

  const { amount } = topUpBalance;

  const promises = [];

  let count = Math.ceil(amount / ONE_PAYMENT_LIMIT);

  console.log('count', count);

  for (let i = 0; i < count; i++) {
    promises.push(
      graphQLClient.mutate({
        mutation: topUpBalanceMutation,
        variables: {
          input: {
            ...topUpBalance,
            amount: amount / count,
            transactionId: uuid(),
          },
        },
      }),
    );
  }

  const responses = await Promise.allSettled(promises);

  let error: Error;

  const processedPaymentsSum = responses.reduce((acc, response) => {
    if (response.status !== 'rejected') {
      const { net } = response.value.data.topUpAccountBalance;

      return (acc += net);
    }

    error = response.reason;

    return acc;
  }, 0);

  if (processedPaymentsSum === 0) {
    throw error;
  }

  return {
    amount: processedPaymentsSum,
  };
}
