/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { DownloadTransactionsAction, DownloadTransactionsActionTypes, DownloadTransactionsFilter } from './actions';

export interface DownloadTransactionsState {
  filter?: DownloadTransactionsFilter;
  error?: Error;
  loading?: boolean;
  searchString: string;
  locale?: string;
  startDate?: Date;
  startTime?: string;
  endDate?: Date;
  endTime?: string;
  initiateTransactionsExportResponseId?: string;
  url?: string;
}

let nowDate = new Date();

let lastSevenDayDate = new Date();
lastSevenDayDate.setDate(nowDate.getDate() - 7);

export const initialDownloadTransactionsState: DownloadTransactionsState = {
  filter: {},
  loading: false,
  locale: 'RU',
  searchString: '',
  startDate: lastSevenDayDate,
  endDate: nowDate,
  startTime: '00:00',
  endTime: '23:59',
  initiateTransactionsExportResponseId: '',
  url: '',
};

export default function downloadTransactionsReducer(
  state = initialDownloadTransactionsState,
  action: DownloadTransactionsAction,
) {
  switch (action.type) {
    case DownloadTransactionsActionTypes.DOWNLOAD_TRANSACTIONS_REQUEST:
      const { filter, searchString } = action;

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        filter: { $set: filter },
        searchString: { $set: searchString },
        endDate: { $set: action.endDate ?? nowDate },
        startDate: { $set: action.startDate ?? lastSevenDayDate },
        startTime: { $set: action.startTime },
        endTime: { $set: action.endTime },
      });

    case DownloadTransactionsActionTypes.DOWNLOAD_TRANSACTIONS_SUCCESS:
      return update(state, {
        initiateTransactionsExportResponseId: { $set: action.id },
        url: { $set: action.url },
      });

    case DownloadTransactionsActionTypes.DOWNLOAD_TRANSACTIONS_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case DownloadTransactionsActionTypes.DOWNLOAD_TRANSACTIONS_ERROR_RESET:
      return update(state, {
        error: { $set: null },
      });

    case DownloadTransactionsActionTypes.DOWNLOAD_TRANSACTIONS_RESET:
      return initialDownloadTransactionsState;

    case DownloadTransactionsActionTypes.DISABLE_SUBSCRIPTION:
      return update(state, {
        loading: { $set: false },
      });

    default:
      return state;
  }
}
