/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { Category, Service } from '../types';
import { DEFAULT_SERVICE_FILTER, ListServicesAction, ListServicesActionTypes, ListServicesFilter } from './actions';

export interface ServicesState {
  currentPage: number;
  error?: Error;
  filter?: ListServicesFilter;
  loading?: boolean;
  locale?: string;
  pressedService?: Service | Category | null;
  services: Service[] | Category[];
  searchString: string;
  selectedServices: Array<any>;
  size: number;
  total: number;
}

const size: number = 20;
export const MAX_SIZE = 2000;

export const initialServicesState: ServicesState = {
  currentPage: 1,
  filter: DEFAULT_SERVICE_FILTER,
  loading: false,
  pressedService: null,
  services: [],
  searchString: '',
  selectedServices: [],
  size,
  total: 0,
};

export default function servicesReducer(state = initialServicesState, action: ListServicesAction) {
  switch (action.type) {
    case ListServicesActionTypes.LIST_SERVICES_REQUEST:
      const { currentPage, filter, locale, searchString, size } = action;

      return update(state, {
        $unset: ['error'],
        filter: { $set: filter },
        loading: { $set: true },
        locale: { $set: locale },
        currentPage: { $set: currentPage },
        searchString: { $set: searchString },
        size: { $set: size },
      });

    case ListServicesActionTypes.LIST_SERVICES_SUCCESS:
      return update(state, {
        loading: { $set: false },
        services: { $set: action.services },
        total: { $set: action.total },
      });

    case ListServicesActionTypes.LIST_SERVICES_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case ListServicesActionTypes.SELECT_SERVICE:
      const selectedServices = state.selectedServices.filter((item) => {
        return item.id !== action.service.id;
      });

      if (selectedServices.length < state.selectedServices.length) {
        return update(state, {
          selectedServices: { $set: selectedServices },
        });
      }

      return update(state, {
        selectedServices: { $push: [action.service] },
      });

    case ListServicesActionTypes.RESET_SELECTED_SERVICE:
      return update(state, {
        selectedServices: { $set: initialServicesState.selectedServices },
      });

    case ListServicesActionTypes.RESET_SERVICES_STATE:
      return initialServicesState;

    case ListServicesActionTypes.PRESS_SERVICE:
      return update(state, {
        pressedService: { $set: action.service },
      });

    case ListServicesActionTypes.RESET_PRESS_SERVICE:
      return update(state, {
        pressedService: { $set: initialServicesState.pressedService },
      });

    case ListServicesActionTypes.RESET_LIST_SERVICES_ERROR:
      return update(state, { $unset: ['error'] });

    case ListServicesActionTypes.GET_LOCALE:
      return update(state, {
        locale: { $set: action.locale },
      });

    default:
      return state;
  }
}
