/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { DeviceChange } from '../../../../components/cashCollections/types';
import { ConfirmCashCollectionDepositActions, ConfirmCashCollectionDepositTypes } from './action';

export interface ConfirmCashCollectionDepositState {
  error?: Error;
  loading?: boolean;
  cashCollection?: DeviceChange;
  note?: string;
  isSuccess?: boolean;
}

export const initialConfirmCashCollectionDepositState: ConfirmCashCollectionDepositState = {
  loading: false,
  error: null,
  note: '',
  isSuccess: false,
};

export default function confirmCashCollectionDepositReducer(
  state = initialConfirmCashCollectionDepositState,
  action: ConfirmCashCollectionDepositActions,
) {
  switch (action.type) {
    case ConfirmCashCollectionDepositTypes.CONFIRM_CASH_COLLECTION_DEPOSIT_REQUEST:
      return update(state, {
        loading: { $set: true },
        note: { $set: action.note },
      });

    case ConfirmCashCollectionDepositTypes.CONFIRM_CASH_COLLECTION_DEPOSIT_SUCCESS:
      return update(state, {
        cashCollection: { $set: action.cashCollection },
        isSuccess: { $set: true },
        loading: { $set: false },
      });

    case ConfirmCashCollectionDepositTypes.CONFIRM_CASH_COLLECTION_DEPOSIT_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case ConfirmCashCollectionDepositTypes.CONFIRM_CASH_COLLECTION_DEPOSIT_RESET:
      return update(state, { $set: initialConfirmCashCollectionDepositState });

    default:
      return state;
  }
}
