/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/averspay';
import {
  ConfirmCashCollectionReceive,
  ConfirmCashCollectionReceiveActions,
  confirmCashCollectionReceiveFailed,
  ConfirmCashCollectionReceiveSuccess,
  confirmCashCollectionReceiveSuccess,
  ConfirmCashCollectionReceiveTypes,
} from './actions';
import { confirmCashCollectionReceiveMutation } from './mutation';

export default function confirmCashCollectionReceiveEpic(action$) {
  return action$.pipe(
    filter(
      (action: ConfirmCashCollectionReceiveActions) =>
        action.type === ConfirmCashCollectionReceiveTypes.CONFIRM_CASH_COLLECTION_RECEIVE_REQUEST,
    ),
    switchMap((action: ConfirmCashCollectionReceive) =>
      confirmCashCollectionReceive(action.confirmCashCollectionReceive)
        .then((action) => confirmCashCollectionReceiveSuccess(action.cashCollection))
        .catch((error) => confirmCashCollectionReceiveFailed(error)),
    ),
  );
}

export async function confirmCashCollectionReceive({ id, note, actualBills }): Promise<ConfirmCashCollectionReceiveSuccess> {
  const graphQLClient = await getClient();

  const input = {
    id,
    note,
    actualBills,
  };

  const {
    data: { confirmCashCollectionReceive },
  } = await graphQLClient.mutate({
    mutation: confirmCashCollectionReceiveMutation,
    variables: {
      input,
    },
  });

  return confirmCashCollectionReceiveSuccess(confirmCashCollectionReceive);
}
