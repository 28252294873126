/* External dependencies */
import gql from 'graphql-tag';

export const confirmCashCollectionDepositMutation = gql`
  mutation confirmCashCollectionDeposit($input: ConfirmCashCollectionDepositInput!) {
    confirmCashCollectionDeposit(input: $input) {
      id
    }
  }
`;
