/*Local dependencies */
import { ActualBills, DeviceChange } from '../../types';

export enum ConfirmCashCollectionReceiveTypes {
  CONFIRM_CASH_COLLECTION_RECEIVE_REQUEST = 'CONFIRM_CASH_COLLECTION_RECEIVE_REQUEST',
  CONFIRM_CASH_COLLECTION_RECEIVE_SUCCESS = 'CONFIRM_CASH_COLLECTION_RECEIVE_SUCCESS',
  CONFIRM_CASH_COLLECTION_RECEIVE_ERROR = 'CONFIRM_CASH_COLLECTION_RECEIVE_ERROR',
  CONFIRM_CASH_COLLECTION_RECEIVE_RESET = 'CONFIRM_CASH_COLLECTION_RECEIVE_RESET',
  CHANGE_CONFIRM_CASH_RECEIVE_COLLECTION = 'CHANGE_CONFIRM_CASH_RECEIVE_COLLECTION',
}

export type ConfirmCashCollectionReceiveActions =
  | ConfirmCashCollectionReceive
  | ConfirmCashCollectionReceiveSuccess
  | ConfirmCashCollectionReceiveError
  | ConfirmCashCollectionReceiveReset
  | ChangeConfirmCashCollectionReceive;

export interface ConfirmCashCollectionReceive {
  type: ConfirmCashCollectionReceiveTypes.CONFIRM_CASH_COLLECTION_RECEIVE_REQUEST;
  confirmCashCollectionReceive: {
    id: string;
    note: string;
    actualBills: ActualBills[];
  };
}

export interface ConfirmCashCollectionReceiveSuccess {
  type: ConfirmCashCollectionReceiveTypes.CONFIRM_CASH_COLLECTION_RECEIVE_SUCCESS;
  cashCollection: DeviceChange;
}

export interface ConfirmCashCollectionReceiveError {
  type: ConfirmCashCollectionReceiveTypes.CONFIRM_CASH_COLLECTION_RECEIVE_ERROR;
  error: Error;
}

export interface ConfirmCashCollectionReceiveReset {
  type: ConfirmCashCollectionReceiveTypes.CONFIRM_CASH_COLLECTION_RECEIVE_RESET;
}

export interface ChangeConfirmCashCollectionReceive {
  type: ConfirmCashCollectionReceiveTypes.CHANGE_CONFIRM_CASH_RECEIVE_COLLECTION;
  actualBills: { index: number; count: number };
}

export function confirmCashCollectionReceive(id: string, note: string, actualBills: ActualBills[]): ConfirmCashCollectionReceive {
  return {
    type: ConfirmCashCollectionReceiveTypes.CONFIRM_CASH_COLLECTION_RECEIVE_REQUEST,
    confirmCashCollectionReceive: { id, note, actualBills },
  };
}

export function confirmCashCollectionReceiveSuccess(cashCollection: DeviceChange): ConfirmCashCollectionReceiveSuccess {
  return {
    type: ConfirmCashCollectionReceiveTypes.CONFIRM_CASH_COLLECTION_RECEIVE_SUCCESS,
    cashCollection,
  };
}

export function confirmCashCollectionReceiveFailed(error: Error): ConfirmCashCollectionReceiveError {
  return {
    type: ConfirmCashCollectionReceiveTypes.CONFIRM_CASH_COLLECTION_RECEIVE_ERROR,
    error,
  };
}

export function confirmCashCollectionReceiveReset(): ConfirmCashCollectionReceiveReset {
  return {
    type: ConfirmCashCollectionReceiveTypes.CONFIRM_CASH_COLLECTION_RECEIVE_RESET,
  };
}

export function changeConfirmCashCollectionReceive({ index, count }): ChangeConfirmCashCollectionReceive {
  return {
    type: ConfirmCashCollectionReceiveTypes.CHANGE_CONFIRM_CASH_RECEIVE_COLLECTION,
    actualBills: {
      index,
      count,
    },
  };
}
