// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { Account, AccountType } from '../../../../../components/accounts/listAccounts/types';
import { CreateAccountAction, CreateAccountActionType, CreateAccountForm } from './action';

export interface CreateAccountState {
  form: CreateAccountForm;
  account?: Account
  error?: Error
  loading: boolean
  isSuccess: boolean
  errorModal: boolean
}

export const initialCreateAccountState: CreateAccountState = {
  loading: false,
  form: {
    name: '',
    mcc: '',
    accountType: AccountType.PERSONAL
  },
  errorModal: false,
  isSuccess: false,
}

export default function createAccountReducer(state = initialCreateAccountState, action: CreateAccountAction) {
  switch (action.type) {
    case CreateAccountActionType.CREATE_ACCOUNT_REQUEST:
      return update(state, {
        loading: { $set: true }
      })

    case CreateAccountActionType.CREATE_ACCOUNT_SUCCESS:
      return update(state, {
        loading: { $set: false },
        account: { $set: action.account },
        isSuccess: { $set: true }
      })

    case CreateAccountActionType.CREATE_ACCOUNT_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
        errorModal: { $set: true }
      })

    case CreateAccountActionType.RESET_CREATE_ACCOUNT:
      return update(state, {
        account: { $set: null },
        loading: { $set: false },
        isSuccess: { $set: false },
        error: { $set: null },
        form: {
          $set:
          {
            name: "",
            mcc: "",
            accountType: AccountType.PERSONAL
          }
        }
      })

    case CreateAccountActionType.RESET_CREATE_ACCOUNT_ERROR:
      return update(state, {
        error: { $set: null },
      })

    case CreateAccountActionType.CLOSE_ERROR_MODAL:
      return update(state, {
        errorModal: { $set: false }
      })

    case CreateAccountActionType.CREATE_ACCOUNT_FORM:
      return update(state, {
        form: {
          $merge: action.payload
        }
      })

    default:
      return state;
  }
}