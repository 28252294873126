/* External dependencies */
import gql from 'graphql-tag';

export const confirmCashCollectionReceiveMutation = gql`
  mutation confirmCashCollectionReceive($input: ConfirmCashCollectionReceiveInput!) {
    confirmCashCollectionReceive(input: $input) {
      id
    }
  }
`;
