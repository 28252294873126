/* External dependencies */
import { debounceTime, filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/averspay';
import { getUserRole } from '../../../components/common/helpers';
import { UserRole } from '../../../components/common/roles';
import { SessionUser } from '../../../components/login/redux/actions';
import {
  ListTransactions,
  ListTransactionsAction,
  ListTransactionsActionTypes,
  listTransactionsFailed,
  listTransactionsSucceeded,
  ListTransactionsSuccess,
} from './actions';
import { listPaymentsQuery } from './queries';
import { Transaction } from './types';

export default function listTransactionsEpic(action$, state$) {
  return action$.pipe(
    filter((action: ListTransactionsAction) => action.type === ListTransactionsActionTypes.LIST_TRANSACTIONS_REQUEST),
    debounceTime(500),
    switchMap((action: ListTransactions) =>
      listTransactions(action, state$.value.transactions?.size, state$.value.login.currentUser).catch((error) =>
        listTransactionsFailed(error),
      ),
    ),
  );
}

export async function listTransactions(
  { currentPage, filter, locale, searchString, startDate, endDate, startTime, endTime }: ListTransactions,
  size: number,
  user: SessionUser,
): Promise<ListTransactionsSuccess> {
  const graphQLClient = await getClient();
  const userRole = getUserRole(user);

  const input = {
    filter,
    from: (currentPage - 1) * size,
    locale,
    query: searchString,
    size,
    ...(startDate ? { startDate: getFullTime(new Date(startDate), startTime) } : {}),
    ...(endDate ? { endDate: getFullTime(new Date(endDate), endTime, true) } : {}),
  };

  const response = await graphQLClient.query({
    errorPolicy: 'none',
    query: getQuery(userRole),
    variables: {
      input,
    },
  });

  const { data } = response;

  const { total, payments } = data.listTransactions ?? data.listPayments;

  const filteredPayments = payments.filter((payment: Transaction) => payment);

  return listTransactionsSucceeded(total, filteredPayments);
}

function getQuery(userRole: string) {
  switch (userRole) {
    case UserRole.USER:
    case UserRole.DEALER:
      return listPaymentsQuery;

    default:
      return listPaymentsQuery;
  }
}

export function getFullTime(providedDate: Date, time: string, isEndTime: boolean = false) {
  if (time) {
    const timeString = time; // Your time string
    const [hours, minutes] = timeString.split(':').map(Number);

    const date = new Date(providedDate);
    date.setHours(hours, minutes, 0, 0);

    const timestamp = date.getTime();

    if (!isEndTime) {
      return timestamp;
    }

    return timestamp + 59 * 1000;
  }

  return providedDate.getTime();
}
