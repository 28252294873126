// External dependencies
import { filter, switchMap } from 'rxjs/operators';

// Local depedencies
import { getClient } from '../../../../../clients/averspay';
import { Account } from '../../../../../components/accounts/listAccounts/types';
import { CreateAccountAction, CreateAccountActionType, createAccountFailure, CreateAccountRequest, createAccountSucceeded } from './action';
import { createAccountMutation } from './mutation';

export default function createAccountEpic(action$) {
  return action$.pipe(
    filter((action: CreateAccountAction) => action.type === CreateAccountActionType.CREATE_ACCOUNT_REQUEST),
    switchMap((action: CreateAccountRequest) =>
      createAccount(action)
        .then(createAccountSucceeded)
        .catch(createAccountFailure),
    ),
  );
}

export async function createAccount({ mcc, name, user, __typename }: CreateAccountRequest): Promise<Account> {
  const graphQLClient = await getClient();

  const {
    data: { createAccount },
  } = await graphQLClient.mutate({
    mutation: createAccountMutation,
    variables: {
      input: {
        mcc,
        name,
        user,
        __typename
      },
    },
  });

  return createAccount;
}