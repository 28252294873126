/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { ActualBills, DeviceChange } from '../../types';
import { ConfirmCashCollectionReceiveActions, ConfirmCashCollectionReceiveTypes } from './actions';

export interface ConfirmCashCollectionReceivState {
  error?: Error;
  loading?: boolean;
  cashCollection?: DeviceChange;
  note?: string;
  isSuccess?: boolean;
  actualBills?: ActualBills[];
  validationPassed?: boolean;
  errors?: ActualBills[];
}

export const initialConfirmCashCollectionReceiveState: ConfirmCashCollectionReceivState = {
  error: null,
  loading: false,
  note: '',
  isSuccess: false,
  actualBills: [
    {
      count: 0,
      amount: 0,
      bill: 0,
    },
  ],
  validationPassed: false,
  errors: [
    {
      amount: null,
      count: null,
      bill: null,
    },
  ],
};

export default function confirmCashCollectionReceiveReducer(
  state = initialConfirmCashCollectionReceiveState,
  action: ConfirmCashCollectionReceiveActions,
) {
  switch (action.type) {
    case ConfirmCashCollectionReceiveTypes.CONFIRM_CASH_COLLECTION_RECEIVE_REQUEST:
      return update(state, {
        loading: { $set: true },
        note: { $set: action.confirmCashCollectionReceive.note },
        actualBills: { $set: action.confirmCashCollectionReceive.actualBills },
        errors: { $set: initialConfirmCashCollectionReceiveState.errors },
      });

    case ConfirmCashCollectionReceiveTypes.CONFIRM_CASH_COLLECTION_RECEIVE_SUCCESS:
      return update(state, {
        cashCollection: { $set: action.cashCollection },
        isSuccess: { $set: true },
        loading: { $set: false },
      });

    case ConfirmCashCollectionReceiveTypes.CONFIRM_CASH_COLLECTION_RECEIVE_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case ConfirmCashCollectionReceiveTypes.CONFIRM_CASH_COLLECTION_RECEIVE_RESET:
      return update(state, { $set: initialConfirmCashCollectionReceiveState });

    case ConfirmCashCollectionReceiveTypes.CHANGE_CONFIRM_CASH_RECEIVE_COLLECTION:
      const { index, count } = action.actualBills;

      const { updatedActualBills, updatedErrors, validationPassed } = validateAndUpdateActualBills(
        index,
        count,
        state.actualBills,
        state.errors,
      );

      return update(state, {
        actualBills: { $set: updatedActualBills },
        errors: { $set: updatedErrors },
        validationPassed: { $set: validationPassed },
      });

    default:
      return state;
  }
}

function validateAndUpdateActualBills(index: number, count: number, actualBills: ActualBills[], errors: ActualBills[]) {
  const updatedActualBills = [...actualBills];
  const updatedErrors = [...errors];

  const currentBill = updatedActualBills[index].bill;
  const calculatedAmount = count * currentBill;

  updatedActualBills[index] = {
    ...updatedActualBills[index],
    count,
    amount: calculatedAmount,
  };

  const updatedError = { amount: null, count: null, bill: null };

  if (count === null || count === undefined) {
    updatedError.count = 'EnterValidValue';
  } else if (count < 0) {
    updatedError.count = 'EnterValidValue';
  }

  updatedErrors[index] = updatedError;

  const validationPassed = updatedErrors.every((error) => !error.count);

  return { updatedActualBills, updatedErrors, validationPassed };
}
