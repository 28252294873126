/*Local dependencies */
import { TransactionStatus, TransactionType } from '../../listTransactions/types';

export enum DownloadTransactionsActionTypes {
  DOWNLOAD_TRANSACTIONS_REQUEST = 'DOWNLOAD_TRANSACTIONS_REQUEST',
  DOWNLOAD_TRANSACTIONS_SUCCESS = 'DOWNLOAD_TRANSACTIONS_SUCCESS',
  DOWNLOAD_TRANSACTIONS_ERROR = 'DOWNLOAD_TRANSACTIONS_ERROR',
  DOWNLOAD_TRANSACTIONS_ERROR_RESET = 'DOWNLOAD_TRANSACTIONS_ERROR_RESET',
  DOWNLOAD_TRANSACTIONS_RESET = 'DOWNLOAD_TRANSACTIONS_RESET',
  DISABLE_SUBSCRIPTION = 'DISABLE_SUBSCRIPTION',
}

export type DownloadTransactionsAction =
  | DownloadTransactions
  | DownloadTransactionsSuccess
  | DownloadTransactionsError
  | DownloadTransactionsErrorReset
  | DownloadTransactionsDisableSubscription
  | DownloadTransactionsReset;

export interface DownloadTransactionsInput {
  startDate?: Date;
  startTime?: string;
  endDate?: Date;
  endTime?: string;
  locale?: string;
  searchString?: string;
  filter?: DownloadTransactionsFilter;
}

export interface DownloadTransactionsFilter {
  accountId?: string;
  status?: TransactionStatus[];
  type?: TransactionType;
  userId?: string;
  service?: { id: string };
}

export interface DownloadTransactions {
  type: DownloadTransactionsActionTypes.DOWNLOAD_TRANSACTIONS_REQUEST;
  startDate?: Date;
  startTime?: string;
  endDate?: Date;
  endTime?: string;
  locale?: string;
  searchString?: string;
  filter?: DownloadTransactionsFilter;
}

export interface DownloadTransactionsSuccess {
  type: DownloadTransactionsActionTypes.DOWNLOAD_TRANSACTIONS_SUCCESS;
  id: string;
  url: string;
}

export interface DownloadTransactionsError {
  type: DownloadTransactionsActionTypes.DOWNLOAD_TRANSACTIONS_ERROR;
  error: Error;
}

export interface DownloadTransactionsErrorReset {
  type: DownloadTransactionsActionTypes.DOWNLOAD_TRANSACTIONS_ERROR_RESET;
}

export interface DownloadTransactionsDisableSubscription {
  type: DownloadTransactionsActionTypes.DISABLE_SUBSCRIPTION;
}

export interface DownloadTransactionsReset {
  type: DownloadTransactionsActionTypes.DOWNLOAD_TRANSACTIONS_RESET;
}

export function downloadTransactions({
  startDate,
  startTime,
  endDate,
  endTime,
  locale,
  searchString,
  filter,
}: DownloadTransactionsInput): DownloadTransactions {
  return {
    type: DownloadTransactionsActionTypes.DOWNLOAD_TRANSACTIONS_REQUEST,
    startDate,
    startTime,
    endDate,
    endTime,
    locale,
    searchString,
    filter,
  };
}

export function downloadTransactionsSucceeded(id: string, url: string): DownloadTransactionsSuccess {
  return {
    type: DownloadTransactionsActionTypes.DOWNLOAD_TRANSACTIONS_SUCCESS,
    id,
    url,
  };
}

export function downloadTransactionsFailed(error: Error): DownloadTransactionsError {
  return {
    type: DownloadTransactionsActionTypes.DOWNLOAD_TRANSACTIONS_ERROR,
    error,
  };
}

export function downloadTransactionsFailedReset(): DownloadTransactionsErrorReset {
  return {
    type: DownloadTransactionsActionTypes.DOWNLOAD_TRANSACTIONS_ERROR_RESET,
  };
}

export function downloadTransactionsReset(): DownloadTransactionsReset {
  return {
    type: DownloadTransactionsActionTypes.DOWNLOAD_TRANSACTIONS_RESET,
  };
}

export function downloadTransactionsDisableSubscription(): DownloadTransactionsDisableSubscription {
  return {
    type: DownloadTransactionsActionTypes.DISABLE_SUBSCRIPTION,
  };
}
