/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { ListTransactionsFilter } from '../../../../components/transactions/listTransactions/actions';
import { getClient } from '../../../../clients/averspay';
import { getFullTime } from '../../../../components/transactions/listTransactions/epics';
import {
  DownloadTransactionsAction,
  DownloadTransactionsActionTypes,
  downloadTransactionsFailed,
  downloadTransactionsSucceeded,
  DownloadTransactionsSuccess,
} from './actions';
import { initiateTransactionsExportQuery } from './mutations';

export default function downloadTransactionsEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: DownloadTransactionsAction) =>
        action.type === DownloadTransactionsActionTypes.DOWNLOAD_TRANSACTIONS_REQUEST,
    ),
    switchMap(() =>
      downloadTransactions(
        state$.value.transactions?.filter,
        state$.value.transactions?.locale,
        state$.value.transactions?.searchString,
        state$.value.transactions?.startDate,
        state$.value.transactions?.endDate,
        state$.value.transactions?.startTime,
        state$.value.transactions?.endTime,
      )
        .then((action) => downloadTransactionsSucceeded(action.id, action.url))
        .catch((error) => downloadTransactionsFailed(error)),
    ),
  );
}

export async function downloadTransactions(
  filter: ListTransactionsFilter,
  locale: string,
  searchString: string,
  startDate: Date,
  endDate: Date,
  startTime: string,
  endTime: string,
): Promise<DownloadTransactionsSuccess> {
  const graphQLClient = await getClient();

  const input = {
    filter,
    locale,
    query: searchString,
    ...(startDate ? { startDate: getFullTime(new Date(startDate), startTime) } : {}),
    ...(endDate ? { endDate: getFullTime(new Date(endDate), endTime, true) } : {}),
  };

  const response = await graphQLClient.query({
    errorPolicy: 'none',
    query: initiateTransactionsExportQuery,
    variables: {
      input,
    },
  });

  const {
    data: {
      initiateTransactionsExport: { id, url },
    },
  } = response;

  return downloadTransactionsSucceeded(id, url);
}
