/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { ListTransactionsAction, ListTransactionsActionTypes, ListTransactionsFilter } from './actions';
import { Transaction } from './types';

export interface TransactionsState {
  currentPage: number;
  filter?: ListTransactionsFilter;
  error?: Error;
  loading?: boolean;
  payments: Transaction[];
  selectedTransactions?: [];
  searchString: string;
  locale?: string;
  size: number;
  total: number;
  startDate?: Date;
  startTime?: string;
  endDate?: Date;
  endTime?: string;
}

const size: number = 20;

let nowDate = new Date();
// Set by default 7 days ago.
let lastSevenDate = new Date();
lastSevenDate.setDate(lastSevenDate.getDate() - 7);

export const initialTransactionsState: TransactionsState = {
  currentPage: 1,
  filter: {},
  loading: true,
  locale: 'RU',
  payments: [],
  searchString: '',
  selectedTransactions: [],
  startDate: lastSevenDate,
  endDate: nowDate,
  size,
  startTime: '00:00',
  endTime: '23:59',   
  total: 0,
};

export default function transactionsReducer(state = initialTransactionsState, action: ListTransactionsAction) {
  switch (action.type) {
    case ListTransactionsActionTypes.LIST_TRANSACTIONS_REQUEST:
      const { currentPage, filter, searchString } = action;

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        filter: { $set: filter },
        currentPage: { $set: currentPage },
        searchString: { $set: searchString },
        endDate: { $set: action.endDate ?? nowDate },
        startDate: { $set: action.startDate ?? lastSevenDate },
        startTime: { $set: action.startTime },
        endTime: { $set: action.endTime },
      });

    case ListTransactionsActionTypes.LIST_PAYMENTS_REQUEST: {
      const { currentPage, filter, searchString } = action;

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        filter: { $set: filter },
        currentPage: { $set: currentPage },
        searchString: { $set: searchString },
        endDate: { $set: action.endDate ?? nowDate },
        startDate: { $set: action.startDate ?? lastSevenDate },
      });
    }

    case ListTransactionsActionTypes.LIST_TRANSACTIONS_SUCCESS:
      return update(state, {
        loading: { $set: false },
        payments: { $set: action.payments },
        total: { $set: action.total },
      });

    case ListTransactionsActionTypes.LIST_TRANSACTIONS_USER_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        filter: { $set: action.filter },
        currentPage: { $set: action.currentPage },
        searchString: { $set: action.searchString },
        endDate: { $set: action.endDate },
        startDate: { $set: action.startDate },
      });

    case ListTransactionsActionTypes.LIST_TRANSACTIONS_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case ListTransactionsActionTypes.SET_TRANSACTIONS_START_DATE:
      return update(state, {
        startDate: { $set: action.startDate },
      });

    case ListTransactionsActionTypes.SET_TRANSACTIONS_END_DATE:
      const { endDate } = action;

      return update(state, {
        endDate: { $set: endDate },
      });

    case ListTransactionsActionTypes.LIST_TRANSACTIONS_ERROR_RESET:
      return update(state, {
        error: { $set: null },
      });

    default:
      return state;
  }
}
