/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';
import { getClient } from '../../../../clients/averspay';

/* Local dependencies */
import {
  ConfirmCashCollectionDeposit,
  ConfirmCashCollectionDepositActions,
  confirmCashCollectionDepositFailed,
  confirmCashCollectionDepositSuccess,
  ConfirmCashCollectionDepositSuccess,
  ConfirmCashCollectionDepositTypes,
} from './action';
import { confirmCashCollectionDepositMutation } from './mutation';

export default function confirmCashCollectionDepositEpic(action$) {
  return action$.pipe(
    filter(
      (action: ConfirmCashCollectionDepositActions) =>
        action.type === ConfirmCashCollectionDepositTypes.CONFIRM_CASH_COLLECTION_DEPOSIT_REQUEST,
    ),
    switchMap((action: ConfirmCashCollectionDeposit) =>
      confirmCashCollectionDeposit(action)
        .then((action) => confirmCashCollectionDepositSuccess(action.cashCollection))
        .catch((error) => confirmCashCollectionDepositFailed(error)),
    ),
  );
}

export async function confirmCashCollectionDeposit({ id, note }): Promise<ConfirmCashCollectionDepositSuccess> {
  const graphQLClient = await getClient();

  const input = {
    id,
    note,
  };

  const {
    data: { confirmCashCollectionDeposit },
  } = await graphQLClient.mutate({
    mutation: confirmCashCollectionDepositMutation,
    variables: {
      input,
    },
  });

  return confirmCashCollectionDepositSuccess(confirmCashCollectionDeposit);
}
