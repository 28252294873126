/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { ServiceDevice } from '../../../../components/devices/terminals/types';
import { CreateService, Currency, DynamicFeeType, RequiredServiceFieldInput, ServiceEndpointInput } from '../../types';
import { CreateServiceActionTypes } from './actions';
import { ParentIdRequiredException } from './exceptions';
import { validateComission, validateServiceFields } from './validate';
import { validateRequiredFieldsInputs } from './validateRequiredFields';

export interface CreateServiceState {
  amountError?: Error;
  countryError?: Error;
  currency?: Currency;
  currencyError?: Error;
  dynamicFeePercentError?: Error;
  dynamicFeeStepsField?: Error;
  dynamicFeeType?: string;
  dynamicFlatFeePercentError?: Error;
  error?: Error;
  feeType?: string;
  feeTypeError?: Error;
  flatFeePercentError?: Error;
  isSuccess?: boolean;
  limitError?: Error;
  loading?: boolean;
  logoError?: Error;
  maxAmountError?: Error;
  minAmountError?: Error;
  nameEnError?: Error;
  nameKyError?: Error;
  nameRuError?: Error;
  requiredFields: RequiredServiceFieldInput[];
  selectedServiceEndpoint?: string;
  selectedParentIdLabel?: string;
  devices?: ServiceDevice[];
  service?: CreateService;
  serviceEndpoint?: ServiceEndpointInput;
  serviceEndpointError?: Error;
  shouldShowConfirmationPopup?: boolean;
  statusError?: Error;
  isFormChanged?: boolean;
}

export const initialCreateServiceState: CreateServiceState = {
  currency: Currency.KGS,
  dynamicFeeType: DynamicFeeType.AMOUNT,
  isSuccess: false,
  shouldShowConfirmationPopup: false,
  service: {
    currency: Currency.KGS,
    country: '',
    devices: [],
    fees: {},
    logo: {
      url: '',
    },
    maxAmount: 0,
    minAmount: 0,
    name_en: '',
    name_ru: '',
    name_ky: '',
    requiredFields: [
      {
        fieldId: '',
        fieldIdError: '',
        inputMask: '',
        label_en: '',
        label_ky: '',
        label_ru: '',
        labelKyError: '',
        labelRuError: '',
        labelEnError: '',
        maxLength: null,
        minLength: null,
        prefix: '',
        keyboardType: null,
        value: null,
        valueError: '',
        hasSpaceAfterPrefix: false,
      },
    ],
    parentId: '',
    slug: '',
    serviceEndpoint: {
      id: '',
    },
    supportsDecimals: true,
  },
};

export default function createServiceReducer(state = initialCreateServiceState, action: CreateServiceAction) {
  switch (action.type) {
    case CreateServiceActionTypes.CREATE_SERVICE_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case CreateServiceActionTypes.CREATE_SERVICE_SUCCESS:
      return update(state, {
        isSuccess: { $set: true },
        loading: { $set: false },
        service: { $set: action.service },
        shouldShowConfirmationPopup: { $set: true },
      });

    case CreateServiceActionTypes.CREATE_SERVICE_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
        shouldShowConfirmationPopup: { $set: true },
      });

    case CreateServiceActionTypes.RESET_ERROR:
      return update(state, {
        $unset: ['error'],
      });

    case CreateServiceActionTypes.RESET_CREATE_SERVICE_STATE:
      return update(state, { $set: initialCreateServiceState });

    case CreateServiceActionTypes.UPDATE_CREATE_SERVICE_FIELDS:
      return validateServiceFields(state, action.updates);

    case CreateServiceActionTypes.SET_PARENT_ID:
      const change: any = {};

      if (!action.parentId) {
        change.parentIdError = {
          $set: new ParentIdRequiredException(),
        };
      } else {
        change['$unset'] = ['parentIdError'];
      }

      return update(state, {
        ...change,
        service: {
          $set: update(state.service, {
            parentId: { $set: action.parentId },
          }),
        },
      });

    case CreateServiceActionTypes.SELECT_FEE_TYPE:
      return validateComission(state, action.fee);

    case CreateServiceActionTypes.SELECT_DEVICE:
      const { selectedDevice } = action;

      const isDeviceAlreadySelected = state.service?.devices?.some((device) => device.id === selectedDevice.id);

      if (isDeviceAlreadySelected) {
        return state;
      }

      return update(state, {
        service: {
          $set: update(state.service, {
            devices: { $push: [selectedDevice] },
          }),
        },
      });

    case CreateServiceActionTypes.REMOVE_DEVICE:
      return update(state, {
        service: {
          $set: update(state.service, {
            devices: {
              $set: state.service?.devices.filter((device: ServiceDevice) => device.id !== action.selectedDevice.id),
            },
          }),
        },
      });

    case CreateServiceActionTypes.RESET_FEE_VALUES:
      return update(state, {
        service: {
          $set: update(state.service, {
            fees: { $set: initialCreateServiceState.service.fees },
          }),
        },
      });

    case CreateServiceActionTypes.RESET_FIXED_COMMISSION:
      return update(state, {
        service: {
          $set: update(state.service, {
            fees: { $set: action.values },
          }),
        },
      });

    case CreateServiceActionTypes.ADD_DYNAMIC_FEE_VALUE:
      return update(state, {
        service: {
          $set: update(state.service, {
            fees: {
              $set: update(state.service.fees, {
                dynamicFeeSteps: { $set: action.values },
              }),
            },
          }),
        },
      });

    case CreateServiceActionTypes.ADD_ROW_INPUTS:
      return update(state, {
        service: {
          $set: update(state.service, {
            fees: {
              $set: update(state.service?.fees, {
                dynamicFeeSteps: {
                  $set: update(state.service?.fees?.dynamicFeeSteps, {
                    $push: [
                      {
                        from: {
                          amount: null,
                          currency: Currency.KGS,
                        },
                        fee: {
                          flatFee: {
                            amount: null,
                            currency: Currency.KGS,
                          },
                        },
                        dynamicFeeType: DynamicFeeType.AMOUNT,
                      },
                    ],
                  }),
                },
              }),
            },
          }),
        },
      });

    case CreateServiceActionTypes.REMOVE_ROW_INPUTS:
      return update(state, {
        service: {
          $set: update(state.service, {
            fees: {
              $set: update(state.service?.fees, {
                dynamicFeeSteps: {
                  $set: update(state.service?.fees?.dynamicFeeSteps, {
                    $splice: [[action.index, 1]],
                  }),
                },
              }),
            },
          }),
        },
      });

    case CreateServiceActionTypes.ADD_REQUIREDFIELDS_VALUE:
      return validateRequiredFieldsInputs(state, action);

    case CreateServiceActionTypes.CREATE_SERVICE_REQUIREDFIELDS_ADD_INPUT:
      return update(state, {
        service: {
          $set: update(state.service, {
            requiredFields: {
              $set: update(state.service?.requiredFields, {
                $push: [
                  {
                    fieldId: null,
                    fieldIdError: '',
                    inputMask: null,
                    label_en: null,
                    label_ky: null,
                    label_ru: null,
                    labelKyError: '',
                    labelRuError: '',
                    labelEnError: '',
                    maxLength: null,
                    minLength: null,
                    prefix: null,
                    keyboardType: null,
                    value: null,
                    valueError: '',
                    hasSpaceAfterPrefix: false,
                  },
                ],
              }),
            },
          }),
        },
      });

    case CreateServiceActionTypes.CREATE_SERVICE_REQUIREDFIELDS_REMOVE_INPUT:
      return update(state, {
        service: {
          $set: update(state.service, {
            requiredFields: {
              $set: update(state.service.requiredFields, {
                $splice: [[action.index, 1]],
              }),
            },
          }),
        },
      });

    case CreateServiceActionTypes.SELECT_DYNAMIC_FEE_TYPE:
      return update(state, {
        service: {
          $set: update(state.service, {
            fees: {
              $set: update(state.service.fees, {
                dynamicFeeSteps: {
                  $set: update(state.service.fees.dynamicFeeSteps, {
                    [action.index]: {
                      dynamicFeeType: {
                        $set: action.feeType,
                      },
                    },
                  }),
                },
              }),
            },
          }),
        },

        dynamicFeeType: {
          $set: update(state.dynamicFeeType, {
            $set: action.feeType,
          }),
        },
      });

    case CreateServiceActionTypes.RESET_ALL_DYNAMIC_INPUTS:
      return update(state, {
        service: {
          $set: update(state.service, {
            fees: {
              $set: update(state.service?.fees, {
                dynamicFeeSteps: {
                  $set: initialCreateServiceState.service?.fees?.dynamicFeeSteps,
                },
              }),
            },
          }),
        },
      });

    case CreateServiceActionTypes.RESET_DYNAMIC_FLATFEEPERCENT_VALUE:
      return update(state, {
        service: {
          $set: update(state.service, {
            fees: {
              $set: update(state.service.fees, {
                dynamicFeeSteps: {
                  $set: update(state.service.fees.dynamicFeeSteps, {
                    [action.index]: {
                      fee: {
                        $set: action.value,
                      },
                    },
                  }),
                },
              }),
            },
          }),
        },
      });

    case CreateServiceActionTypes.RESET_MAX_AMOUNT_VALUE:
      return update(state, {
        service: {
          $set: update(state.service, {
            maxAmount: { $set: null },
          }),
        },
      });

    case CreateServiceActionTypes.RESET_MIN_AMOUNT_VALUE:
      return update(state, {
        service: {
          $set: update(state.service, {
            minAmount: { $set: null },
          }),
        },
      });

    default:
      return state;
  }
}
