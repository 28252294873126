/* Local dependencies */
import { Account, AccountType } from "../../../../../components/accounts/listAccounts/types";
import { UserInput } from "../types";

export enum CreateAccountActionType {
  CREATE_ACCOUNT_REQUEST = "CREATE_ACCOUNT_REQUEST",
  CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS",
  CREATE_ACCOUNT_ERROR = "CREATE_ACCOUNT_ERROR",
  RESET_CREATE_ACCOUNT = "RESET_CREATE_ACCOUNT",
  RESET_CREATE_ACCOUNT_ERROR = "RESET_CREATE_ACCOUNT_ERROR",
  CLOSE_ERROR_MODAL = "CLOSE_ERROR_MODAL",
  CREATE_ACCOUNT_FORM = "CREATE_ACCOUNT_FORM"
}

export interface CreateAccountForm {
  name: string;
  mcc: string;
  accountType: string;
}

export interface CreateAccountFormAction {
  type: CreateAccountActionType.CREATE_ACCOUNT_FORM,
  payload: Partial<CreateAccountForm>
}

export interface CloseErrorModalAction {
  type: CreateAccountActionType.CLOSE_ERROR_MODAL
}

export interface ResetCreateAccount {
  type: CreateAccountActionType.RESET_CREATE_ACCOUNT
}

export interface ResetCreateAccountError {
  type: CreateAccountActionType.RESET_CREATE_ACCOUNT_ERROR
}

export interface CreateAccountRequest {
  type: CreateAccountActionType.CREATE_ACCOUNT_REQUEST,
  mcc?: string,
  name: string,
  user: UserInput,
  __typename: AccountType,
}

export interface CreateAccountSuccess {
  type: CreateAccountActionType.CREATE_ACCOUNT_SUCCESS,
  account: Account;
}

export interface CreateAccountError {
  type: CreateAccountActionType.CREATE_ACCOUNT_ERROR,
  error: Error;
}

export type CreateAccountAction = CreateAccountRequest | CreateAccountSuccess | CreateAccountError | ResetCreateAccount | ResetCreateAccountError | CloseErrorModalAction | CreateAccountFormAction

export interface CreateAccount {
  mcc?: string,
  name: string,
  user: UserInput,
  __typename: AccountType,
}

export function createAccount(params: CreateAccount): CreateAccountRequest {
  return {
    type: CreateAccountActionType.CREATE_ACCOUNT_REQUEST,
    ...params
  };
}

export function createAccountSucceeded(account: Account): CreateAccountSuccess {
  return {
    type: CreateAccountActionType.CREATE_ACCOUNT_SUCCESS,
    account
  };
}

export function createAccountFailure(error: Error): CreateAccountError {
  return {
    type: CreateAccountActionType.CREATE_ACCOUNT_ERROR,
    error
  };
}

export function resetCreateAccount(): ResetCreateAccount {
  return {
    type: CreateAccountActionType.RESET_CREATE_ACCOUNT,
  };
}

export function resetCreateAccountError(): ResetCreateAccountError {
  return {
    type: CreateAccountActionType.RESET_CREATE_ACCOUNT_ERROR,
  };
}

export function closeErrorModal(): CloseErrorModalAction {
  return {
    type: CreateAccountActionType.CLOSE_ERROR_MODAL,
  };
}

export const updateCreateAccountForm = (formData: Partial<CreateAccountForm>): CreateAccountFormAction => ({
  type: CreateAccountActionType.CREATE_ACCOUNT_FORM,
  payload: formData
});